import { render, staticRenderFns } from "./simCard.vue?vue&type=template&id=062d20cd&scoped=true"
import script from "./simCard.vue?vue&type=script&lang=js"
export * from "./simCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "062d20cd",
  null
  
)

export default component.exports